import $ from 'jquery';

// import Swiper JS
import Swiper from 'swiper';

//typekit
  (function (d) {
    var config = {
        kitId: 'gpc4xxn',
        scriptTimeout: 3000,
        async: true
      },
      h = d.documentElement,
      t = setTimeout(function () {
        h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
      }, config.scriptTimeout),
      tk = d.createElement("script"),
      f = false,
      s = d.getElementsByTagName("script")[0],
      a;
    h.className += " wf-loading";
    tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
    tk.async = true;
    tk.onload = tk.onreadystatechange = function () {
      a = this.readyState;
      if (f || a && a != "complete" && a != "loaded") return;
      f = true;
      clearTimeout(t);
      try {
        Typekit.load(config)
      } catch (e) {}
    };
    s.parentNode.insertBefore(tk, s)
  })(document);

document.addEventListener('DOMContentLoaded', () => {
  //swiper
  const swiper = new Swiper('.swiper-container', {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 0,
    loop: true,
    speed: 2000,

    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },

    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });



  //navi
  var current = false;
  var $gBtn = $('#js-headerMenu');
  var $gNav = $('#js-headerNav');

  //sp
  $gBtn.on('click', function () {
    if (current == false) {
      current = true;
      if (!$(this).hasClass('is-close')) {
        $gNav.stop().addClass('is-enable');
        $gBtn.addClass('is-close');
        current = false;
      } else {
        $gNav.stop().removeClass('is-enable');
        $gBtn.removeClass('is-close');
        current = false;
      }
    } else {
      return false;
    }
  });

  //sp tel link
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
    $('.js-tel').each(function () {
      var str = $(this).html();
      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/-/g, '')).append(str + '</a>'));
      } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/-/g, '')).append(str + '</a>'));
      }
    });
  }
})
